import React from "react";
import {graphql, useStaticQuery, Link, navigate} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {StaticImage} from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo"

import BlogFeed from "../components/blog-feed";
import PortfolioFeed from "../components/portfolio-feed";
import Quote from "../components/quote";
import DownloadForm from "../components/download-form";
import CTA from "../components/cta";

import nineWebDesignElements from "../images/backrounds/9-web-design-elements.jpg";
import {createContact} from "../api";
import {buildUTMCustomFields} from "../helpers/activeCampaignCustomFieldsBuilder";

const mailingListId = process.env.GATSBY_ACTIVE_CAMPAIGN_729_SOLUTIONS_MAILING_LIST_ID;
const subscriberWebDesignChecklistFormTag = process.env.GATSBY_SUBSCRIBER_WEB_DESIGN_CHECKLIST_FORM_TAG;

const Index = ({location}) => {

  const handleOnDownload = async (email) => {
    const customFields = buildUTMCustomFields(location.search);
    await createContact({
      contact: {email}, mailingListId, tags: [subscriberWebDesignChecklistFormTag], customFields
    });
    navigate('../../9-web-design-elements-you-cant-do-without-in-2020-ty');
  }

return (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="9 Web Design Elements You Can&#039;t Do Without"
      description="An effective web design is more than just pretty shapes and colors. Read this article and learn about web design qualities a great website can’t go without."
    />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${nineWebDesignElements})`,
            maxHeight: "56vw",
            height: "80vh",
          }}
        ></div>
      </section>
      <section>
        <div className="container pb-6">
          <div className="row">
            <div className="col mt-n6">
              <div className="card custom-cards card-border">
                <div className="card-body">
                  <h1 class="m-0">
                    9 web design elements you can’t do without in 2020
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pb-6">
          <div className="row">
            <div className="col-lg-6">
              <blockquote className="border-left border-primary border-3">
                <p>“Fast, cheap, and good…pick two.”</p>
              </blockquote>
              <p>Have you ever heard that quote? It goes on to say,</p>
              <blockquote className="border-left border-primary border-3">
                <p>
                  “If it’s fast and cheap it won’t be good. If it’s cheap and
                  good, it won’t be fast. If it’s fast and good, it won’t be
                  cheap.”
                </p>
              </blockquote>
              <Quote
                quote="A fast, cheap, and good website is a unicorn; a mythical creature that exists only in fantasies."/>
              <p>
                Our agency has been operating for 15 years and several of our
                employees have been around for far longer than that. We’ve seen
                and created it all, from the most rudimentary,{" "}
                <a
                  href="http://info.cern.ch/hypertext/WWW/Summary.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  earliest websites
                </a>{" "}
                to today’s most complex solutions. So we say with certainty that a
                fast, cheap, and good website is a unicorn; a mythical creature
                that exists only in fantasies.
              </p>
            </div>
            <div className="col-lg-6">
              <p>
                We’ve seen time and again that an effective website design can be
                an incredibly positive investment in the growth of your business.
                We have also seen how a poorly designed website will do more to
                drive people away than pull them in, costing your business in the
                short-term and adding additional investment to try to fix the
                problematic design.
              </p>
              <Quote quote="The cheap and fast route almost never turns out to be cheap nor fast."/>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light pt-6">
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2>How much does it cost to build a good website?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h3>
                The difference between a $5k website and a $50k website is not
                $45k.
              </h3>
              <p>
                There is a significant difference between building a website for
                $5,000 and a website design that you invest $50,000 in. Many
                inexpensive website designs may look beautiful on the outside, but
                they’ll have a poorly written code and defective functionality
                followed by a long list of symptoms. You will be excited when the
                site is first completed but quickly realize that you are operating
                with your hands tied behind your back as your site fails to
                provide the desired results and becomes increasingly difficult to
                manage.
              </p>
            </div>
            <div className="col-lg-6">
              <p>
                How do you make sure that your new website is designed to be an
                asset for your business instead of a liability?&nbsp;
              </p>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  How can you know what to look for when searching for{" "}
                  <Link to="/why-companies-employing-web-consulting-will-get-better-results-than-you-in-2020/">
                    the right kind of web designer
                  </Link>
                </span>
                </li>
                <li>
                <span className="text-dark">
                  What do you really need to make your website solid?
                </span>
                </li>
                <li>
                <span className="text-dark">
                  What can you afford to skip and where should you be cautious
                  about cutting corners?
                </span>
                </li>
              </ul>
              <p>
                We want to use our expertise to help you build a solid list of
                criteria so you can ensure your end result is a website that you
                can be proud of. A website that is not only pleasing to the eye
                but a fantastic overall experience for your users as well.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light pb-6">
        <div className="container">
          <div className="row mb-4">
            <div className="col">
              <h2>
                Things commonly skipped when building an inexpensive website
              </h2>
              <p>
                If you want to be assured that your website design is worth the
                investment, then make sure not to neglect these nine critical
                qualities of an effective website:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card custom-cards">
                <AnchorLink to="#over-engineer" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-over-engineer.png"
                      alt="Don't Over-Engineer Your Website Design"
                      width={80}
                      className="mr-3"
                    />
                    <p>Don't Over-Engineer Your Website Design</p>
                  </div>
                </AnchorLink>
              </div>
              <div className="card custom-cards">
                <AnchorLink to="#site-structure" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-site-structure.png"
                      alt="Optimized Site Structure"
                      width={80}
                      className="mr-3"
                    />
                    <p>Optimized Site Structure</p>
                  </div>
                </AnchorLink>
              </div>
              <div className="card custom-cards">
                <AnchorLink to="#content" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-content.png"
                      alt="High Quality, Relevant Content"
                      width={80}
                      className="mr-3"
                    />
                    <p>High Quality, Relevant Content</p>
                  </div>
                </AnchorLink>
              </div>
              <div className="card custom-cards">
                <AnchorLink to="#responsiveness" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-responsiveness.png"
                      alt="Mobile Responsiveness"
                      width={80}
                      className="mr-3"
                    />
                    <p>Mobile Responsiveness</p>
                  </div>
                </AnchorLink>
              </div>
              <div className="card custom-cards">
                <AnchorLink to="#images-videos" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-images-videos.png"
                      alt="Images, Graphics and Visuals"
                      width={80}
                      className="mr-3"
                    />
                    <p>Images, Graphics and Visuals</p>
                  </div>
                </AnchorLink>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card custom-cards">
                <AnchorLink to="#ease-of-use" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-usability.png"
                      alt="Ease of Use"
                      width={80}
                      className="mr-3"
                    />
                    <p>Ease of Use</p>
                  </div>
                </AnchorLink>
              </div>
              <div className="card custom-cards">
                <AnchorLink to="#ui-design" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-ui-design.png"
                      alt="Intentional UI Design"
                      width={80}
                      className="mr-3"
                    />
                    <p>Intentional UI Design</p>
                  </div>
                </AnchorLink>
              </div>
              <div className="card custom-cards">
                <AnchorLink to="#speed" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-speed.png"
                      alt="Page Speed"
                      width={80}
                      className="mr-3"
                    />
                    <p>Page Speed</p>
                  </div>
                </AnchorLink>
              </div>
              <div className="card custom-cards">
                <AnchorLink to="#security" className="text-dark">
                  <div className="card-body d-flex align-items-center">
                    <StaticImage
                      src="../images/9-web-design-elements/icon-security.png"
                      alt="Site Security"
                      width={80}
                      className="mr-3"
                    />
                    <p>Site Security</p>
                  </div>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="over-engineer">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-over-engineer.png"
                alt="Don't Over-Engineer Your Website Design"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>Don’t Over-Engineer Your Website Design</h3>
              <p>
                A well designed website will help you achieve an elegant solution
                for your current needs while also providing you the infrastructure
                to support your future goals, all without adding a bunch of
                unnecessary bloat.&nbsp; To quote our founder, “Over-engineering
                is a form of technical debt.&nbsp; It’s just as bad as writing bad
                code. It gets in the way of delivering the thing that our client
                asked for.”&nbsp;
              </p>

              <Quote
                quote="Over-engineering is a form of technical debt. It’s just as bad as writing bad code. It gets in the way of delivering the thing that our client asked for."
                name="Rob Fife"
                company="CEO, 729 Solutions"
              />
              <p>
                You don’t want a site that is anemic either, lacking the necessary
                features and functions to operate at its peak. Experienced web
                designers will have the ability to whittle down your website’s
                design to only what you want and nothing more. Typically this
                requires a greater level of communication between designers and
                developers during the{" "}
                <Link to="/studios-design-process/">planning phases</Link> of your
                project.
              </p>
              <h4>Why Right-Sized Project Scope is Important</h4>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  Inaccurate considerations for your current and future needs
                  will result in a site that is unable to perform at the
                  appropriate level and can stunt your growth
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Too many extra things will cause your project budget and
                  timelines to be bloated and result in a site that performs
                  poorly
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light" id="site-structure">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-site-structure.png"
                alt="Optimized Site Structure"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>Optimized Site Structure</h3>
              <p>
                One of the most critical and also most commonly missed things,
                even amongst some of the top web designers, is an optimized site
                structure.
              </p>
              <p>
                <a
                  href="https://moz.com/beginners-guide-to-seo/technical-seo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Site structure
                </a>{" "}
                is a Search Engine Optimization (SEO) concept that addresses how
                the website content is structured within your site, how your URLs
                are structured, etc…. A well-designed site structure will confirm
                that your site is readable by both your target audience as well as
                search engines.
              </p>
              <p>
                Most web designers and developers have not been trained in SEO.
                Although over the past decade there has been an increasing
                awareness amongst web designers of the importance of SEO, there is
                still a large gap in knowledge as to how their work can have an
                impact on SEO.
              </p>
              <h4>Why Site Structure is Important</h4>
              <ul class="text-primary">
                <li>
                <span className="text-dark">
                  Optimized site structure is the foundation to a technically
                  sound website.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  It makes sure your website is readable by humans and search
                  engines.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Your website’s structure has a massive impact on its overall
                  performance, including paying a major role in the site’s
                  ability to rank in search results.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  An improper site structure can severely limit the
                  effectiveness of other optimizations you make to your site,
                  such as your content, design aesthetic, on-page SEO, digital
                  advertising placement, etc…
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="content">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-content.png"
                alt="High Quality, Relevant Content"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>High Quality, Relevant Content</h3>
              <p>
                A website, or any application for that matter, should not be
                designed around pretty shapes and colors, personal style
                preference, or the opinions of your internal team. Your website’s
                design should be driven by the content it needs to hold.&nbsp;
                What purpose will a beautiful website serve if it lacks important
                information your users are looking for?
              </p>
              <p>
                Researched, written, reviewed and edited – your content should be
                written by subject matter experts, structured in verticles that
                compliment your buyer’s journey, and presented to the user through
                workflows you can monitor and track.
              </p>
              <h4>Why High Quality, Relevant Content is important:</h4>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  Great content builds your SEO. Quality content and SEO are
                  inseparable in today’s digital landscape. Without good SEO, no
                  one will know about your business and what you do.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Strong content inspires confidence in your users that you
                  possess a deep understanding of the subject at hand and are
                  therefore worth paying attention to.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Your content, when written with clear, concise and cohesive
                  messaging, provides users with details to help them make an
                  informed purchasing decision.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Quality, relevant content, smoothly guides users through their
                  buyer’s journey while instilling loyalty and trust in your
                  brand.
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light" id="responsiveness">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-responsiveness.png"
                alt="Mobile Responsiveness"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>
                Mobile Responsiveness or Mobile Friendly accommodations (for B2C
                and B2B)
              </h3>
              <p>
                With more than half of all web traffic and organic{" "}
                <a
                  href="https://www.broadbandsearch.net/blog/mobile-desktop-internet-usage-statistics#post-navigation-5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  searches coming from mobile
                </a>{" "}
                phones today, your website needs to work on multiple screen sizes
                – even if you don’t think users are looking at it from all of
                those screens.
              </p>
              <p>
                Don’t skip the mobile mockups, and certainly don’t skip the
                development time to implement mobile-friendly best
                practices.&nbsp; You might even consider a mobile app as well.
                Could your specific use case benefit from the native functionality
                phones and tablets can offer?
              </p>
              <h4>
                Mobile Responsive Website Design is Important for B2B and B2C
              </h4>
              <p>
                You may be thinking that this point only applies to B2C companies,
                that no doubt draw in large amounts of mobile traffic, but is
                mobile responsiveness really that important for B2B companies?
              </p>
              <p>
                It’s true that on average, B2B companies are likely to receive
                between 20% and 40% of their traffic from mobile users and that
                mobile traffic for B2B companies tends to result in fewer
                conversions than desktop traffic.
              </p>
              <p>
                Don’t be too quick to write off the importance of mobile
                responsive web design for a B2B company.&nbsp; One critical note
                to make, though, is that many times, B2B buyers will have a
                mixed-device buyer’s journey, beginning their research on a mobile
                device and completing on a desktop.
              </p>
              <p>
                This means that the 20-40% of traffic coming to your site from a
                mobile device could actually be an opportunity to make a positive
                first impression and to inspire first-time visitors to return to
                complete their research by visiting your site again from their
                desktop.
              </p>
              <p>
                You only get one chance to make a first impression. Not optimizing
                your site for mobile could mean that you are losing opportunities
                and driving potential customers to your competitor’s
                mobile-friendly site.
              </p>
              <StaticImage
                src="../images/9-web-design-elements/729-Infographic-Mobile-Traffic.jpg"
                alt="729 Infographic Mobile Traffic"
                className="mb-4"
              />
              <p className="text-mute">
                <i>
                  According to the latest data, in 2019, 52.2 percent of all web
                  traffic came from mobile phones (this does not include tablets
                  or other mobile traffic) and 59 percent of organic search
                  traffic came from mobile devices.{" "}
                </i>
                <i>Source: </i>
                <a
                  href="https://www.broadbandsearch.net/blog/mobile-desktop-internet-usage-statistics#post-navigation-5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    Mobile Vs. Desktop Usage (Latest 2020 Data),
                    broadbandsearch.net
                  </i>
                </a>
              </p>
              <h4>Mobile Responsive Website Design is Important for SEO</h4>
              <p>
                Secondary to your users’ experiences with your site, but still
                greatly important, is that{" "}
                <a
                  href="https://webmasters.googleblog.com/2019/05/mobile-first-indexing-by-default-for.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google rewards sites that are mobile friendly
                </a>
                . Google’s mission is to help users find the answers to their
                queries as quickly as possible, which means providing search
                results that are relevant to the intended search and that help the
                user easily find what they are looking for.
              </p>
              <p>
                Due to the fact that the majority of Google’s traffic has been
                coming from mobile devices in steadily increasing volumes over the
                past several years, they recently introduced a mobile-first
                indexing initiative to reward websites that make mobile their
                priority by giving them priority in search engine rankings over
                those that do not.
              </p>
              <h4>Why Mobile Optimization is important:</h4>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  Mobile optimization means higher search rankings, which means
                  greater traffic potential.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Not optimizing your website for mobile means leaving money on
                  the table.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Not optimizing your website for mobile also means you are
                  potentially driving potential customers to your competitors.
                </span>
                </li>
              </ul>
              <p>
                Want to know even more about responsive websites? You’ll want to
                check out our recently updated{" "}
                <Link to="/lets-learn-all-about-responsive-websites/">
                  article all about responsive web design
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="images-videos">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-images-videos.png"
                alt="Images, Graphics and Visuals"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>Relevant, High Resolution Images, Graphics and Videos</h3>
              <p>
                You don’t have to include all of these things, but when you do,
                make them striking! Crisp, high resolution assets that are
                optimized for the web can make an enormous difference on the
                overall look and feel of your site, not to mention its
                performance.
              </p>
              <p>
                We recommend having a professional photographer who knows how to
                shoot for the web take customized photos, or at the very least,
                spend the money on nice stock photos.&nbsp; Then, don’t forget to
                have your designer compress the images so you preserve clarity and
                avoid pixelation.
              </p>
              <p>
                If you really want to make sure your site operates at optimal
                levels, you’ll get your SEO professional involved in the
                preparation of your photos BEFORE uploading them to your site.
                This actually takes us back to our first point about site
                structure, which can even impact how images are displayed and
                behave throughout your website.
              </p>
              <p>
                There are loads of commonly missed opportunities along with
                detrimental effects that are difficult to correct post launch when
                it comes to your site’s creative assets.
              </p>
              <h4>Why images are important:</h4>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  No one wants to read wall after wall of text.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Some users are more visual and will grasp concepts better with
                  a graphic or image to go along with the text.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Visual assets help confirm the users understanding of the text
                  as well.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Images, graphics and videos are often the biggest culprits in
                  poor site health, which has a domino effect on your site’s
                  overall performance, starting with killing the user experience
                  and ending with a loss in your online presence.
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light" id="ease-of-use">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-usability.png"
                alt="Ease of Use"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>Ease of Use for your users, and for you!</h3>
              <p>
                What’s the point of having a website if no one is able to use
                it!&nbsp; First and foremost, we want your users to be able to
                navigate your site, find what they need, and accomplish tasks
                without roadblocks.&nbsp; Secondly – we want the website to be
                easy for you to maintain – if you so choose.&nbsp; A good web
                consultant will spend time planning for these things.
              </p>
              <h4>Why UX and ease of use is important:</h4>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  If users have a poor experience using your site, they’ll
                  likely abandon ship and seek out your competitors instead.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  If your website is difficult for you to update and maintain,
                  then you won’t do it! An up to date website is key for staying
                  relevant and competitive in your industry.
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="ui-design">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-ui-design.png"
                alt="Intentional UI Design"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>An intentional UI Design</h3>
              <p>
                People often skip the creation of mockups for their website and go
                straight to the build.&nbsp; You could do this mildly
                successfully, but who wants their website to look like the
                competitor?&nbsp; Not you!
              </p>
              <h4>Why mockups and good UI Design is important:</h4>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  Starting with mockups (or even low-fidelity wireframes) helps
                  to ensure everyone is on the same page as to what the site
                  should look like, and that it honors the constraints of your
                  brand – visually and otherwise.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Mockups help to eliminate overbuilding in the development
                  stage -which can save you money.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Good UI ensures a smooth and intuitive user experience.
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light" id="speed">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-speed.png"
                alt="Page Speed"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>Page Speed</h3>
              <p>
                Page speed is such a critical step to address in your website
                build but is often overlooked because it’s nearly ‘invisible’ to
                some of us.&nbsp; If you have a newer laptop and a solid internet
                connection, chances are, your website will load those large images
                and minified scripts with no problem.&nbsp; But if you’re one of
                the XXX people out there with hindered hardware and internet
                connection, PageSpeed could be the reason they abandon their
                shopping cart and visit your competitor.
              </p>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  40% of people abandon a website that takes more than 3 seconds
                  to load.
                </span>
                </li>
                <li>
                <span className="text-dark">
                  If an e-commerce site is making $100,000 per day, a 1 second
                  page delay could potentially cost you $2.5 million in lost
                  sales every year.
                </span>
                </li>
              </ul>
              <p>
                <a
                  href="https://neilpatel.com/blog/loading-time/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Want more stats?
                </a>
              </p>
              <p>
                So as you work to make your site stand out with extra content and
                unique functionality, make sure you’re not sacrificing your page
                load time. If the page won’t load fast enough, nearly half of
                users won’t stick around long enough to ever see it.
              </p>
              <h4>Why it’s important that your website is fast!</h4>
              <ul className="text-priamry">
                <li>
                <span className="text-dark">
                  Not all users have the hardware and internet bandwidth to
                  support slow load times.
                </span>
                </li>
                <li>
                  <span className="text-dark">Most users are impatient.</span>
                </li>
                <li>
                <span className="text-dark">
                  Google drops your ranking if your pagespeed is dragging your
                  SEO down.
                </span>
                </li>
              </ul>
              <p>
                <a
                  href="https://neilpatel.com/blog/loading-time/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Want more stats?
                </a>
              </p>
              <p>
                So as you work to make your site stand out with extra content and
                unique functionality, make sure you’re not sacrificing your page
                load time. If the page won’t load fast enough, nearly half of
                users won’t stick around long enough to ever see it.
              </p>
              <h4>Why it’s important that your website is fast!</h4>
              <ul className="text-primary">
                <li>
                <span className="text-dark">
                  Not all users have the hardware and internet bandwidth to
                  support slow load times.
                </span>
                </li>
                <li>
                  <span className="text-dark">Most users are impatient.</span>
                </li>
                <li>
                <span className="text-dark">
                  Google drops your ranking if your pagespeed is dragging your
                  SEO down.
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="security">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-3 text-center">
              <StaticImage
                src="../images/9-web-design-elements/icon-security.png"
                alt="The “s” in “HTTPS” and other Security Protocol"
                className="mb-4"
              />
            </div>
            <div className="col-lg-9">
              <h3>The “s” in “HTTPS” and other Security Protocol</h3>
              <p>
                Something we see clients undervalue the importance of is security
                on their website.&nbsp; This step is last in our list, but
                probably the most important!&nbsp; Outside of gaining an SSL
                certificate, there are several regulations out there like&nbsp;
                <a
                  href="http://info.729solutions.com/en/ccpa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CCPA
                </a>{" "}
                and GDPR that you need to ensure you’re following.
              </p>
              <p>
                Beyond the credibility your site will gain, the primary reason why
                SSL is used is to protect sensitive data transmitted from and to
                your website, such as login details, signups, addresses and
                payment or personal information. Additionally, having an SSL
                certificate installed can provide a boost in SEO rankings as it
                brings your site in alignment with&nbsp;
                <a
                  href="https://www.youtube.com/watch?v=cBhZ6S0PFCY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google’s HTTPS Everywhere
                </a>{" "}
                initiative.
              </p>
              <p>&nbsp;</p>
              <h4>Why website security is important:</h4>
              <ul className="text-priamry">
                <li>
                <span className="text-dark">
                  Users want to know you’re not selling their information to
                  third parties
                </span>
                </li>
                <li>
                <span className="text-dark">
                  Your SEO is hindered if your website is insecure
                </span>
                </li>
                <li>
                <span className="text-dark">
                  You want to ensure your company is following regulations
                </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-secondary-light">
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2>In Conclusion</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <p>
                So, can you have a quick website spun up for $5k? Sure! But your
                web designer won’t be providing you the due diligence as a trusted
                consultant to do so and you’re going to miss one or likely
                several, if not all, of the elements in this list.&nbsp; A cheap,
                fast website isn’t going to have the research and thought put into
                it that it needs in order to be an asset to your business.
              </p>
              <p>
                A high-quality website will work for you – helping users to find
                you without any extra effort, and to guide your users through
                their buyer’s journey in a natural way.&nbsp; In addition, a
                high-quality website is going to provide an elegant design with an
                intuitive user experience that’s catered to your specific user
                persona’s needs.
              </p>
              <p>
                UI/UX consultants will take the time to get to know you, your
                business, your clients, and understand how they think.&nbsp; But
                all of this requires a bit of research, some critical thinking,
                and time – which as you know, translates to money.
              </p>
            </div>
            <div className="col-lg-6">
              <p>
                As with most any investment in your business, we know that it’s
                natural to seek out the least expensive and most efficient
                approach, without sacrificing quality. This is a difficult balance
                to strike, especially when you still have to{" "}
                <b>
                  <a
                    href="http://info.729solutions.com/how-to-convince-your-boss-web-design-consultant-roi"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    convince your leadership
                  </a>
                </b>{" "}
                of what you believe to be the best option after all of your
                research. And remember:
              </p>

              <blockquote className="border-left border-primary border-3">
                <p>“Fast, cheap and good … pick just two words to live by.”</p>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark text-center py-6">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-white">
                Get our web design elements checklist!
              </h2>
              <p className="text-white">
                Download a condensed version of this list for quick reference and
                reach out if we can help answer any of your questions.
              </p>
              <DownloadForm onSubmit={handleOnDownload}/>
            </div>
          </div>
        </div>
      </section>
      <PortfolioFeed className="pt-6"/>
      <section>
        <div className="container py-6 mb-6">
          <div className="row text-center">
            <div className="col">
              <Link to="/web-design-consulting-services/">
                <svg
                  className="text-primary-light mb-3"
                  height={90}
                  viewBox="0 0 144 144"
                  width={90}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="12.96"
                      x2="87.72"
                      y1="51.35"
                      y2="51.35"
                    />
                    <path
                      d="M131,45.45v80.63H13V23.82H115.3"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="28.7"
                      x2="36.56"
                      y1="36.98"
                      y2="36.98"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="48.36"
                      x2="56.23"
                      y1="36.98"
                      y2="36.98"
                    />
                    <path
                      d="M71.67,67.38,119.17,20A7,7,0,0,1,129,20h0a7,7,0,0,1,0,9.84h0L81.5,77.21"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M78,70.86a12.88,12.88,0,0,0-18.22,0h0C55,75.7,54.26,94.62,54.26,94.62S73.18,93.93,78,89.09a12.88,12.88,0,0,0,0-18.23Z"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="28.7"
                      x2="115.23"
                      y1="109.75"
                      y2="109.75"
                    />
                  </g>
                </svg>
                <p>Website Design Consulting</p>
              </Link>
            </div>
            <div className="col">
              <Link to="/user-experience-consultant-services/">
                <svg
                  className="text-primary-light mb-3"
                  height={90}
                  viewBox="0 0 144 144"
                  width={90}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <rect
                      fill="none"
                      height="70.04"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.07 0.06) rotate(-0.09)"
                      width="48.25"
                      x="17.49"
                      y="12.02"
                    />
                    <rect
                      fill="none"
                      height="38.91"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.17 0.06) rotate(-0.09)"
                      width="48.25"
                      x="17.59"
                      y="94.51"
                    />
                    <rect
                      fill="none"
                      height="38.91"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.05 0.15) rotate(-0.09)"
                      width="48.25"
                      x="78.16"
                      y="11.93"
                    />
                    <rect
                      fill="none"
                      height="70.04"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.15 0.15) rotate(-0.09)"
                      width="48.25"
                      x="78.26"
                      y="63.29"
                    />
                  </g>
                </svg>
                <p>UI/UX Design Consulting</p>
              </Link>
            </div>
            <div className="col">
              <Link to="/branding-design-logo-design/">
                <svg
                  className="text-primary-light mb-3"
                  height={90}
                  viewBox="0 0 144 144"
                  width={90}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M17.81,42.14l0-24.06,24.06,0"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M126.1,42l0-24.06L102,18"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M17.9,102.3l0,24.06,24.06,0"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M126.19,102.14l0,24.06-24.06,0"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M15.85,72.23s24-32.12,56.1-32.17,56.2,32,56.2,32-24,32.12-56.1,32.17S15.85,72.23,15.85,72.23Z"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <circle
                      cx="72"
                      cy="72.14"
                      fill="none"
                      r="16.66"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                  </g>
                </svg>
                <p>Branding & Identity Design Consulting</p>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary pb-6">
        <div className="container">
          <div className="row">
            <div className="col mt-n6">
              <div className="card custom-cards card-border">
                <div className="card-body">
                  <h3>About 729 Solutions</h3>
                  <p>
                    729 is a team of hard-working designers, developers, project
                    managers, and solutions architects. We are problem solvers who
                    come up with new ways to make technology faster, safer, and
                    easier. We are builders who create experiences and products
                    that people love. We integrate complex systems in the simplest
                    ways possible. We help fast-growing companies and their
                    customers do more.
                  </p>
                  <p>
                    We’ve been engineering amazing technology for 15 years and we
                    want to do it for you.
                  </p>
                  <CTA
                    href="/contact-us/"
                    className="btn btn-primary"
                    text="Let's Chat About Your Project"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlogFeed containerClassname="py-6"/>
    </Layout>
  )
};

export default Index;
